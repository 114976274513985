.pagination {
  @apply flex w-full text-xs justify-between;
}

.pagination .disabled {
  @apply text-gray-500;
}

.pagination a {
  @apply block box-content border-b-4 border-red border-opacity-0;
}

.pagination a:hover {
  @apply border-opacity-100;
}

.pagination a.active {
  @apply font-semibold px-2 border-opacity-100;
}
