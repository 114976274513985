:root {
  --color-black: black;
  --color-white: white;
  --color-play-red: #cc3333;
  --color-buffer-gray: rgba(156, 163, 175, 0.5);
}

:fullscreen .fullscreen\:scale-150 {
  transform: scale(1.5);
}

::backdrop {
  background-color: var(--color-black);
}

.video-container:fullscreen video {
  height: 100vh;
  width: 100vw;
}

/* Video progress bar styles */
.video-progress,
.video-buffer-progress {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 9999px;
}

/* Buffer progress styles */
.video-buffer-progress::-webkit-progress-bar {
  background-color: transparent;
  border: none;
}

.video-buffer-progress::-webkit-progress-value {
  background-color: var(--color-buffer-gray);
  border-radius: 9999px;
  border: none;
}

.video-buffer-progress::-moz-progress-bar {
  background-color: var(--color-buffer-gray);
  border-radius: 9999px;
  border: none;
}

.video-buffer-progress {
  border: none;
}

/* Playback progress styles */
.video-progress::-webkit-progress-bar {
  background-color: transparent;
}

.video-progress::-webkit-progress-value {
  background-color: var(--color-play-red);
  border-radius: 9999px;
}

.video-progress::-moz-progress-bar {
  background-color: var(--color-play-red);
  border-radius: 9999px;
}
