.entry-opinion {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "title title"
    "lead byline";
}

/* Override to single column when there is no author image */
.entry-opinion.no-author-picture {
  grid-template-columns: 1fr;
}

.px-entry {
  @apply px-4;
}

.py-entry-vignette {
  padding-bottom: 0.667rem;
  @apply pt-4;
}

.entry-heading {
  line-height: 1.0667;
  font-size: 2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  @apply font-stag-serif font-semibold hyphens-manual;
}

.entry-heading-extra {
  line-height: 0.93333;
  font-size: 3.3rem;
  letter-spacing: -0.3px;
  @apply font-franklin-gothic font-condensed uppercase;
}

.entry-heading-opinion {
  line-height: 1.0667;
  font-size: 2rem;
  @apply font-stag-serif italic font-light;
}

.entry-heading-related-opinion {
  line-height: 1.136;
  font-size: 1.4667rem;
  @apply font-stag-serif italic font-light;
}

@screen tablet {
  .entry-opinion {
    grid-template-areas:
      "title byline"
      "lead byline";
  }

  .px-entry {
    @apply px-3.5;
  }

  .py-entry-vignette {
    padding-top: 1.176rem;
    padding-bottom: 0.58rem;
  }

  .entry-heading {
    line-height: 1.077;
    font-size: 3.059rem;
    @apply pr-16;
  }

  .entry-heading-extra {
    line-height: 0.89;
    font-size: 4.82rem;
    letter-spacing: -0.41px;
  }

  .entry-heading-opinion {
    line-height: 1.125;
    font-size: 1.88rem;
  }

  .entry-heading-related-opinion {
    line-height: 1.125;
    font-size: 1.88rem;
    @apply font-stag-serif italic font-light;
  }
}
