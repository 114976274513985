@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./vendor.css";

@import "./components/etc.css";
@import "./components/article.css";
@import "./components/pages.css";
@import "./components/index.css";
@import "./components/entries.css";
@import "./components/widgets.css";
@import "./components/headerNavigation.css";
@import "./components/ads.css";
@import "./components/pagination.css";
@import "./components/register.css";
@import "./components/embeds.css";
@import "./components/games_override.css";
@import "./components/play.css";
@import "./typography.css";

@import "./themes.css";
