@layer base {
  /* Libre Franklin */

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 300;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-300.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 300;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-300italic.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 400;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-regular.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 400;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-italic.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 500;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-500.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 500;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-500italic.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 600;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-600.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 600;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-600italic.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 700;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-700.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 700;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-700italic.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: normal;
    font-weight: 800;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-800.woff2")
        format("woff2");
  }

  @font-face {
    font-family: "Libre Franklin";
    font-style: italic;
    font-weight: 800;
    src: local(""),
      url("./fonts/libre-franklin/libre-franklin-v7-latin-800italic.woff2")
        format("woff2");
  }

  /* End Libre Franklin */

  /* Source Serif */

  @font-face {
    font-family: "Source Serif Pro";
    src: url("./fonts/source-serif-pro/source-serif-pro-v11-latin-regular.woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Source Serif Pro";
    src: url("./fonts/source-serif-pro/source-serif-pro-v11-latin-italic.woff2");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Source Serif Pro";
    src: url("./fonts/source-serif-pro/source-serif-pro-v11-latin-700.woff2");
    font-weight: 700;
  }

  @font-face {
    font-family: "Source Serif Pro";
    src: url("./fonts/source-serif-pro/source-serif-pro-v11-latin-700italic.woff2");
    font-weight: 700;
    font-style: italic;
  }

  /* End Source Serif */

  /* Stag Serif */

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-thin.woff2");
    font-weight: 100;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-thin-italic.woff2");
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-light.woff2");
    font-weight: 300;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-light-italic.woff2");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-regular.woff2");
    font-weight: 400;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-regular-italic.woff2");
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-medium.woff2");
    font-weight: 500;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-medium-italic.woff2");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-semibold.woff2");
    font-weight: 600;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-semibold-italic.woff2");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-black.woff2");
    font-weight: 900;
  }

  @font-face {
    font-family: "Stag Serif";
    src: url("./fonts/stag-serif/stag-black-italic.woff2");
    font-weight: 900;
    font-style: italic;
  }

  /* End Stag Serif */

  /* Stag Stencil */
  @font-face {
    font-family: "Stag Stencil";
    src: url("./fonts/stag-stencil/stag-stencil-light.woff2");
    font-weight: 300;
  }

  @font-face {
    font-family: "Stag Stencil";
    src: url("./fonts/stag-stencil/stag-stencil-light-italic.woff2");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Stag Stencil";
    src: url("./fonts/stag-stencil/stag-stencil-bold.woff2");
    font-weight: 700;
  }

  @font-face {
    font-family: "Stag Stencil";
    src: url("./fonts/stag-stencil/stag-stencil-bold-italic.woff2");
    font-weight: 700;
    font-style: italic;
  }

  /* End Stag Stencil */

  @font-face {
    font-family: "Franklin Gothic";
    src: url("./fonts/franklin-gothic-std/franklin-gothic-std-extra-cond.woff2");
    font-stretch: extra-condensed;
  }
}

@layer utilities {
  .font-condensed {
    font-stretch: extra-condensed;
  }
}

.font-box {
  @apply bg-black;
  @apply bg-opacity-50;
  margin-left: 0.2em;
  padding-left: 0.1em;
  padding-right: 0.1em;
  box-shadow: 0 0 0 0.2em rgba(0, 0, 0, 0.5);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

strong {
  @apply font-semibold;
}

html {
  font-size: 15px;
  line-height: 1.47;
}

@screen tablet {
  html {
    font-size: 17px;
  }
}
