/* Landing page overrides */
.embed-block #puzzle-portal .portal-main .game-section {
  background-color: transparent;
}

.embed-block #puzzle-portal .portal-main .game-section .game-section-inner {
  padding: 1.5rem;
  margin: none;
  max-width: none;
  width: 100%;
  box-sizing: border-box;
}

#puzzle-portal .portal-main .game-section h2 {
  font-family: "Libre Franklin";
  font-weight: 700;
  font-size: 1.75rem;
  /* TODO would like to embed tailwind styles */
  color: rgb(97 97 97);
}

#puzzle-portal .portal-main .powered-section-inner {
  margin: 0;
  max-width: unset;
}

#puzzle-portal .powered-section .powered-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
}

#puzzle-portal .powered-section .powered-card .powered-card__more-puzzles {
  /* Magic number from third party */
  margin-left: 1.6em;
}

@media screen and (max-width: 750px) {
  #puzzle-portal .powered-section .powered-card .powered-card__more-puzzles {
    /* Magic number from third party */
    margin-left: 1.2em;
  }
}

#puzzle-portal .portal-main .card {
  box-shadow: none;
}

/* Game page overrides */
#puzzle-portal #puzzle-header .puzzle-header__title {
  font-family: "Libre Franklin";
  font-weight: 700;
  font-size: 1.25rem;
}

/* Hide the logo. Yes important is horrible but quite effective */
section.powered-section {
  display: none !important;
}