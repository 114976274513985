:root {
  --color-accent: 0, 0, 0;
}

.theme-etc {
  --color-accent: 227, 6, 19;
}

.theme-kloka-hem {
  --color-accent: 99, 61, 109;
}
