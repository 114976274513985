.button-clicked {
  @apply cursor-default disabled:opacity-75 bg-gray-400 opacity-75;
}
.hide {
  visibility: hidden;
  opacity: 0;
  @apply h-0 w-0;
}
.pop {
  transition: opacity 2s;
}