.keyword-page-header p {
  @apply my-2;
}

.keyword-page-header h2 {
  @apply font-stag-serif font-semibold text-lg;
}

.keyword-page-header a {
  @apply text-red no-underline hover:text-black  hover:underline;
}