/**

When we use the privacy_friendly_embeds extension it will transform an iframe tag to:

<div class="embed">
  <iframe class="embed__element" data-src="https://vimeo.com/123"></iframe>
  <form class="embed__notice">
    <div class="embed__notice__wrapper">
      <div class="embed-symbol"></div>
      <p>Info text</p>
      <button>Button text</button>
      <button>Button text</button>
    </div>
  </form>
</div>

*/

blockquote.twitter-tweet {
  display: none;
}

/* Either the iframe will show or the notice will show */
.embed__element:not([src]) {
  display: none;
}

.embed__element[src] + .embed__notice {
  display: none;
}

/* Little hack to be responsive. Only needed for iframes, not for scripts */
.embed-iframe::before {
  display: block;
  content: '';
  padding-bottom: 56.25%;
  width: 0;
  height: 0;
}

.embed {
  display: flex;
}

form.embed__notice {
  background-image: url('/public/images/blurred-subway.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  display: flex;
  align-items: center;
}

.embed-vimeo form.embed__notice {
  background-image: url('/public/images/blurred-vimeo.jpg');
}

.embed-youtube form.embed__notice {
  background-image: url('/public/images/blurred-youtube.jpg');
}

.embed-twitter form.embed__notice {
  background-image: url('/public/images/blurred-twitter.jpg');
}

.embed__notice__wrapper {
  font-family: Libre Franklin, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: small;
  height: 100%;
  width: 80%;

  background-color: rgba(0, 0, 0, 0.6);
  padding: 2em;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .embed__notice__wrapper {
    width: 55%;
    font-size: medium;
  }
}

.embed-symbol {
  background-image: url('/public/images/info-symbol.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 35px;
  width: 35px;
  margin-top: 1em;
}

.embed__notice button {
  width: inherit;
  font-size: small;
  padding: 0.5rem 1rem;
  margin-right: 1em;
  margin-bottom: 1em;
  background-color:#CC3129;
  
  border: none;
  border-radius: 9999px;
  color: #fff;
  cursor: pointer;
}


/* For cookies page */
button.remove-domain {
  padding: 0.2rem 0.5rem;
  margin-left: 1em;
  background-color: rgb(119, 181, 45);

  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 9999px;
}

button.remove-domain:hover {
  background-color: black;
}