/* Since we use postcss we use @import "tailwindcss/base"; instead of @tailwind base;
This is done in style.css and is not needed to do here

Read more:

https://tailwindcss.com/docs/installation
https://tailwindcss.com/docs/adding-new-utilities.

*/

@layer utilities {
  /* Determine over which columns the entry should span */
  .entry-grid-main {
    grid-column: 1;
  }
  .entry-grid-main-wide {
    grid-column: 1 / 3;
  }
  .entry-grid-main-full {
    grid-column: 1 / -1;
  }
  .entry-grid-second-column {
    grid-column: 2;
    grid-row: 2/-1;
  }
  .entry-grid-third-column {
    grid-column: 3;
    grid-row: 2/-1;
  }
}

@screen tablet {
  .etc-outer-grid {
    grid-template-columns: 1fr;
  }
  .etc-inner-grid {
    grid-template-columns: 1fr 300px;
  }
}

@screen desktop {
  .etc-outer-grid {
    grid-template-columns: 1fr 250px;
  }
  .etc-inner-grid {
    grid-template-columns: 1fr 300px;
  }
}
