@layer utilities {
  .hyphens-manual {
    hyphens: manual;
  }

  .hyphens-auto {
    hyphens: auto;
  }

  .hyphens-none {
    hyphens: none;
  }

  /**
Hyphenate words that are 12 characters or longer and 
ensure that there are 5 characters on each side of the hyphenation.
**/
  .hyphens-lg {
    -webkit-hyphenate-limit-before: 5;
    -webkit-hyphenate-limit-after: 5;
    -webkit-hyphenate-limit-chars: 12 5 5;
    -webkit-hyphenate-limit-lines: 1;
    -webkit-hyphenate-limit-last: always;
    -webkit-hyphenate-limit-zone: 15%;

    -moz-hyphenate-limit-chars: 12 5 5;
    -moz-hyphenate-limit-lines: 1;
    -moz-hyphenate-limit-last: always;
    -moz-hyphenate-limit-zone: 15%;

    -ms-hyphenate-limit-chars: 12 5 5;
    -ms-hyphenate-limit-lines: 1;
    -ms-hyphenate-limit-last: always;
    -ms-hyphenate-limit-zone: 15%;

    hyphenate-limit-chars: 12 5 5;
    hyphenate-limit-lines: 1;
    hyphenate-limit-last: always;
    hyphenate-limit-zone: 15%;
  }
}

.icon-angle-down {
  background-image: url(/public/images/angle-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.bg-gradient-dark {
  background: rgba(0, 0, 0, 0.3);
  background: linear-gradient(0, #000000 0%, #00000000 50%) 0% 0%;
}

.bg-gradient-white {
  background: linear-gradient(0, #ffffff 0%, #ffffff00 80%) 0% 0%;
}

.h-stretch {
  @apply h-screen;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}

.hover-trigger .hover-target {
  display: none;
}

.hover-trigger:hover .hover-target {
  display: block;
}

.py-etc-main {
  padding-top: 0.66667rem;
}

.px-etc-wrapper {
  @apply px-4;
}

main a:hover h1 {
  @apply opacity-70;
}

@screen tablet {
  .py-etc-main {
    padding-top: 1.1765rem;
  }

  .px-etc-wrapper {
    @apply px-6;
  }
}

@layer components {
  .btn {
    @apply bg-red hover:bg-black text-white py-1.5 px-3 tablet:px-5 rounded-full box-border border-red block whitespace-nowrap;
  }
}

@layer utilities {
  .etc-shadow-lg {
    box-shadow: 0 4px 12px hsla(0, 0%, 0%, 0.12) /* Ambient light shadow */,
      0 14px 24px hsla(0, 0%, 0%, 0.16) /* Direct light shadow */;
  }
}


/* Progress bar for video */
:root {
  --youtube-red: #e30613;
}

progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  width: 100%;
  height: 8.4px;
  pointer-events: none;
  position: absolute;
  top: 0;
}

progress::-webkit-progress-bar {
  background-color: #474545;
  border-radius: 2px;
}

progress::-webkit-progress-value {
  background: var(--youtube-red);
  border-radius: 2px;
}

progress::-moz-progress-bar {
  border: 1px solid var(--youtube-red);
  background: var(--youtube-red);
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 8.4px;
  background: transparent;
  cursor: pointer;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  border-radius: 1.3px;
  -webkit-appearance: none;
  transition: all 0.4s ease;
}

input[type=range]::-webkit-slider-thumb {
  height: 16px;
  width: 16px;
  border-radius: 16px;
  background: var(--youtube-red);
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: -1px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}

input[type=range].volume {
  height: 5px;
  background-color: #fff;
}

input[type=range].volume::-webkit-slider-runnable-track {
  background-color: transparent;
}

input[type=range].volume::-webkit-slider-thumb {
  margin-left: 0;
  height: 14px;
  width: 14px;
  background: #fff;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  border: 1px solid transparent;
  background: transparent;
  border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb {
  height: 14px;
  width: 14px;
  border-radius: 50px;
  border: 1px solid var(--youtube-red);
  background: var(--youtube-red);
  cursor: pointer;
  margin-top: 5px;
}

input[type=range]:focus::-moz-range-track {
  outline: none;
}

input[type=range].volume::-moz-range-thumb {
  border: 1px solid #fff;
  background: #fff;
}

.fading {
  opacity: 0;
  transition: opacity 0.15s linear;
}