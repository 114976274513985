@layer components {
  .article-guest-byline p:first-of-type {
    @apply font-bold;
  }

  .article-heading-opinion {
    @apply font-stag-serif text-[2rem] font-light italic leading-[1.0667] tablet:text-[2.5rem] tablet:leading-[1.077];
  }

  .prose-article {
    @apply prose-typography prose-article-blockquote prose-article-figure;
  }

  .prose-feature {
    @apply prose-typography prose-feature-blockquote prose-feature-figure;
  }

  .prose-typography {
    @apply prose-xl prose-red font-serif leading-snug antialiased tablet:prose-lg tablet:leading-normal;
  }

  .prose-feature-figure {
    @apply prose-article-figure prose-figure:-mx-4 tablet:prose-figure:-mx-16 tablet:prose-figure:-my-6 tablet:prose-figure:my-0 tablet:prose-figure:border-y-[2rem] tablet:prose-figure:border-white;
  }

  .prose-article-figure {
    @apply prose-figcaption:mt-0 prose-figcaption:bg-gray-200 prose-figcaption:p-4 prose-figcaption:font-sans prose-figcaption:text-sm prose-figcaption:text-gray-800 tablet:prose-figcaption:mt-0;
  }

  .prose-article-blockquote {
    @apply prose-blockquote:before:mb-1 prose-blockquote:before:block prose-blockquote:before:h-[23px] prose-blockquote:before:w-[31px] prose-blockquote:before:bg-[url(/public/images/ic_blockquote.svg)] prose-blockquote:before:bg-contain prose-blockquote:before:bg-no-repeat;
  }

  .prose-feature-blockquote {
    @apply prose-blockquote:max-w-lg prose-blockquote:font-semibold prose-blockquote:text-black;

    /** Quotation mark: **/
    @apply prose-blockquote:before:block prose-blockquote:before:h-10 prose-blockquote:before:w-12 prose-blockquote:before:bg-[url(/public/images/ic_blockquote_feature.svg)] prose-blockquote:before:bg-contain prose-blockquote:before:bg-no-repeat;
  }

  /* "Byline" for quotes, both for feature/story articles and news articles */
  .prose-feature-blockquote blockquote u,
  .prose-feature-blockquote blockquote p u,
  .prose-article-blockquote blockquote u,
  .prose-article-blockquote blockquote p u {
    @apply block font-sans text-lg font-normal italic leading-normal no-underline;
  }
}

.article-lead-wrapper p {
  margin-top: 1em;
}

.article-lead-wrapper a {
  @apply text-red no-underline hover:text-black hover:underline;
}

.article-lead-wrapper p:first-child {
  display: inline;
  margin-top: 0em;
}

.article-factbox .factbox-body {
  @apply relative overflow-hidden;
  max-height: 8rem;
}

.article-factbox .factbox-body::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(237, 237, 237);
  background: linear-gradient(
    0deg,
    rgba(237, 237, 237, 1) 15%,
    rgba(255, 255, 255, 0) 90%
  );
}

/* speech bubble "notch" black border */
.popup::before {
  @apply absolute -bottom-[16px] right-[10px] block w-0 border-[15px] border-b-0 border-transparent border-t-black content-[''] tablet:right-[18px];
}
/* speech bubble "notch" */
.popup::after {
  @apply absolute -bottom-[15px] right-[10px] block w-0 border-[15px] border-b-0 border-transparent border-t-white content-[''] tablet:right-[18px];
}

.article-factbox input#factbox-toggle:checked ~ .factbox-body {
  max-height: none;
  padding-bottom: 0;
}

.article-factbox input#factbox-toggle:checked ~ .factbox-body::after {
  content: none;
}

.article-factbox .icon-angle-down {
  transition: 0.3s transform ease-in-out;
}

.article-factbox input#factbox-toggle:checked ~ label .icon-angle-down {
  transform: rotate(180deg);
}

.article-factbox input#factbox-toggle:checked ~ .checked\:relative {
  position: relative !important;
}

/* Show faded info, e.g. for comments */
input.show-more-toggle:checked ~ .show-more-body {
  max-height: none;
}

input.show-more-toggle:checked ~ .show-more-body .body-fader {
  display: none;
}

input.show-more-toggle ~ label .icon-angle-down img {
  transition: 0.3s transform ease-in-out;
}

input.show-more-toggle:checked ~ label .icon-angle-down img {
  transform: rotate(180deg);
}

input.show-more-toggle:checked ~ label .hide-comments-text {
  display: inline-block;
}

input.show-more-toggle:checked ~ label .show-comments-text {
  display: none;
}

.article-factbox ul {
  list-style: none;
}

.article-factbox li {
  @apply mb-2;
}

.article-factbox li::before {
  content: "•";
  @apply mr-1 font-bold text-red;

  width: 1em;
  margin-left: -0.7em;
}

/* Factbox for writeup articles which moves the image to the right*/
.writeup-factbox {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* Make the implicit rows 0 or auto in height */
  grid-auto-rows: minmax(0, auto);
  padding-bottom: 1em;
}
.writeup-factbox * {
  grid-column-start: 1;
}

.writeup-factbox figure {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-self: end;
  align-self: start;
  max-width: 120px;
  max-height: 300px;
  /* max-height: 60px; */
  /* Here we would like "grid-row: 1 / -1" but that works only for explicit rows */
  grid-row: 1 / 100;
  grid-column: 2;
}

.writeup-factbox h2 {
  margin-bottom: 0;
  font-size: 1em;
}

.writeup-factbox p {
  margin-top: 0;
  margin-bottom: 0;
}

.writeup-factbox figure img {
  height: 100%;
  width: auto;
  object-fit: contain;
  padding-bottom: 1em;
}

.writeup-factbox figure figcaption {
  display: none;
}

/* Info box for recipie which moves the image to the right*/
.recipe-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* Make the implicit rows 0 or auto in height */
  grid-auto-rows: minmax(0, auto);
}

@screen tablet {
  .recipe-info {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

.recipe-info * {
  grid-column-start: 2;
}

.recipe-info figure {
  @apply mr-4 max-h-80;
  margin-top: 0;
  /* Here we would like "grid-row: 1 / -1" but that works only for explicit rows */
  grid-row: 1 / 100;
  grid-column: 1;
}

.recipe-info figure figcaption {
  display: none;
}

.recipe-instruction figure {
  display: none;
}

.recipe-ingredients {
  --tw-prose-bullets: #e30613;
}

.etc-figcaption {
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.etc-figcaption-credit {
  @apply inline-block text-[#888];
}

.prose-feature figure img + figcaption,
.prose-feature figure video + figcaption,
.prose-article figure img + figcaption,
.prose-article figure video + figcaption {
  @apply etc-figcaption;
}

figure img + figcaption span,
figure video + figcaption span {
  @apply etc-figcaption-credit;
}

/* Story Special Section! */
.prose.prose-special {
  max-width: unset;
}

.prose-special h4 {
  text-align: center;
}

.story-lead p {
  margin-bottom: 0.8rem;
}

/* Make embeds fill the width of the viewport */
.prose-special .embed-block {
  margin-left: -1rem;
  margin-right: -1rem;
}

/* Reset margins */
img.parallax-styling {
  margin: 0;
}

@screen tablet {
  .article-with-sidebar-grid-template {
    grid-template-columns: 1fr;
  }

  .prose-special .embed-block {
    margin-left: -4rem;
    margin-right: -4rem;
  }
}

@screen desktop {
  .article-with-sidebar-grid-template {
    grid-template-columns: 1fr 250px;
  }
}

/* targets the audio container when main article has div with feature or story class */
main:has(article):has(.etc-feature, .etc-story)
  ~ #sticky-audio-player
  .etc-main-audio-container,
main:has(.etc-theme-main .etc-theme-section, .etc-theme-part)
  ~ #sticky-audio-player
  .etc-main-audio-container {
  /* remove grid and do full width to let inner margin   centering */
  @apply tablet:flex tablet:w-full;
}

/* targets the audio component when main article has div with feature or story class */
main:has(article):has(.etc-feature, .etc-story)
  ~ #sticky-audio-player
  .etc-main-audio,
main:has(.etc-theme-main .etc-theme-section, .etc-theme-part)
  ~ #sticky-audio-player
  .etc-main-audio {
  /* center margins with same padding as story and feature text col and grow to cover full width */
  @apply mx-auto flex-grow px-6;
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.prose-feature figure {
  @apply -mx-4;
}

.prose-feature figure img,
.prose-feature figure video {
  @apply w-full;
}

@screen tablet {
  .prose-feature figure {
    @apply -mx-16;
  }

  .prose-feature figure img,
  .prose-feature figure video {
    @apply w-full;
  }
}

/* Common figcaption styling for all article types */
.prose-article figure figcaption,
.prose-feature figure figcaption,
.prose-article div figcaption,
.prose-feature div figcaption {
  @apply bg-gray-100 p-4 font-sans text-sm text-gray-600;
}

.prose-article figure figcaption .etc-figcaption-credit,
.prose-feature figure figcaption .etc-figcaption-credit,
.prose-article div figcaption .etc-figcaption-credit,
.prose-feature div figcaption .etc-figcaption-credit {
  @apply mt-1 font-sans text-[#888];
}

/* Story Special Section specific styles */
.prose-special figure {
  @apply -mx-4;
}

@screen tablet {
  .prose-special figure {
    @apply -mx-16;
  }
}

.rotate-270 {
  transform: rotate(270deg);
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.rotate-270 {
  transform: rotate(270deg);
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}

.teaser-section {
  @apply relative max-h-screen overflow-hidden;
}

.teaser-section::after {
  content: "";
  @apply bg-gradient-white absolute top-0 h-full w-full;
}
