.shadow-when-stuck.stuck {
  box-shadow: 0 2px 3px hsla(0, 0%, 0%, 0.12), 0 3px 6px hsla(0, 0%, 0%, 0.14);
}

.hidden-when-stuck.stuck,
.stuck .hidden-when-stuck {
  @apply hidden;
}

.block-when-stuck.stuck,
.stuck .block-when-stuck {
  @apply block;
}

.header-navigation ul {
  @apply h-full flex space-x-6;
}

.header-navigation ul li {
  @apply h-full;
}

.header-navigation ul li a {
  @apply h-full flex items-center border-b-4 border-opacity-0 border-skin-accent;
}

.header-navigation ul li a[href="/ekonomi"] {
  @apply border-section-ekonomi border-opacity-0;
}

.header-navigation ul li a[href="/klimat-miljo"] {
  @apply border-section-klimat-miljo border-opacity-0;
}

.header-navigation ul li a[href="/kultur-noje"] {
  @apply border-section-kultur-noje border-opacity-0;
}

.header-navigation ul li a:hover,
.header-navigation ul li.current a {
  @apply border-opacity-100;
}

.logo img {
  transition: 0.2s cubic-bezier(0.22, 1, 0.36, 1);
}

.header-mobile {
  display: grid;
  grid-template-columns: 90px minmax(0.667rem, 1fr) minmax(max-content, 25rem);
  grid-template-areas: "logo . content";
  transition: 0.2s ease-out;
}

.header-mobile .logo img {
  width: 90px;
}

.header-mobile.stuck .logo img {
  width: 60px;
}

.header-desktop {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 103px 1fr;
  grid-template-areas: "logo content";
  transition: 0.2s ease-out;
}

.header-desktop .logo img {
  width: 103px;
}

.header-desktop.stuck .logo img {
  width: 60px;
}

/* When the top banner ad is user, the ad_show_sometimes_controller will add the pushed-down class to these */
#header-mobile-logo.pushed-down, #header-tablet-logo.pushed-down, #header-mobile-menu.pushed-down, #header-tablet-menu.pushed-down {
  transition: top 0.8s;
}

/* Here we push down. It should actually be 60px but due to a rendering bug for chromium we get a gap */
#header-mobile-logo.pushed-down, #header-tablet-logo.pushed-down, #header-mobile-menu.pushed-down, #header-tablet-menu.pushed-down {
  top: 59px;
}

#top-banner {
  transition: height 0.8s;
}

#top-banner.show-me {
  height: 60px;
}

.header-desktop-subgrid {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: "left . right";
}

@layer base {
  .grid-left-right {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas: "left . right";
  }
}
